import React from 'react';
import { MEDIA_BASE_URL } from '../../utils/constant';

function IconText({ src, name, active, categoryId, onClick }) {
  let path = src;

  if (path && !path.startsWith('http')) {
    path = MEDIA_BASE_URL + path;
  }

  if (path && active) {
    path = path.replace(/(\.png|\.jpg|\.jpeg|\.gif)$/, '_active$1');
  }

  return (
    <div className={'category' + (active ? ' active' : '')} onClick={onClick} category-id={categoryId}>
      {path && <img alt={name} src={path} />}
      <span>{name}</span>
    </div>
  );
}

export default IconText;
