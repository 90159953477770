function useLocalization() {
  // Access the Redux state
  //const qrocde = useSelector((state) => state.qrcode);

  return {
    currentCurrency: { id: 2 },
  };
}

export default useLocalization;
