import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';

function NotFoundPage() {
  return (
    <section className="layout default-layout not-found-layout">
      <Helmet htmlAttributes={{ lang: 'en', dir: 'ltr' }}>
        <title>Not Found</title>
      </Helmet>

      <div className="main-card">
        <div className="section not-found-error-page">
          <img class="logo" src="https://getmenu.b-cdn.net/global/get_menu.jpg" alt="GET MENU" />
          <h1 className="brand-name">Get Menu</h1>
          <h1 style={{ fontFamily: 'Arial, sans-serif' }}>We're sorry!</h1>
          <p>Apologies for the inconvenience! The menu you are looking for could not be found.</p>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default NotFoundPage;
