import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import useCart from '../../hooks/useCart';

function MiniCart() {
  const { allowToOrder, miniCartButtonEanbled, totalQuantity } = useCart();
  const {} = useCart();

  const { collapseSidebar } = useProSidebar();

  if (!allowToOrder || !miniCartButtonEanbled) {
    return null;
  }

  return (
    <>
      <div className="mini-cart" onClick={() => collapseSidebar()}>
        <img src="/assets/icon/cart.svg" alt="" />
        <p className="total-items">{totalQuantity || 0}</p>
      </div>
      <MiniCart />
    </>
  );
}

export default MiniCart;
