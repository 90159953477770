import { useSelector } from 'react-redux';

function useQRCode() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);
  const footerNavigationEnabled = qrcode?.settings?.FOOTER_NAVIGATION_ENABLED === 'true';
  const showLanguageSwitch = qrcode?.settings?.showLanguageSwitch === 'true';
  const showLocalSwitch = qrcode?.settings?.showLocalSwitch === 'true';

  return { id: qrcode.id, uuid: qrcode.uuid, footerNavigationEnabled, showLanguageSwitch, showLocalSwitch };
}

export default useQRCode;
