import React from 'react';
import { MEDIA_BASE_URL } from '../../utils/constant';

function Icon({ group, active, onSelect }) {
  let path = group.thumbnail;
  if (path) {
    if (!path.startsWith('http')) {
      path = MEDIA_BASE_URL + path;
    }

    if (active) {
      path = path.replace(/(\.png|\.jpg|\.jpeg|\.gif)$/, '_active$1');
    }
  }
  return (
    <div className={'group ' + (active ? 'active' : '')} onClick={onSelect} group-id={group.id}>
      <img alt={group.name} src={path} />
    </div>
  );
}

export default Icon;
