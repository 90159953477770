import React from 'react';
import { Product } from './Product';

const HealthayGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {products.map((product, index) => (
              <Product key={index} product={product} showPrice={false} />
            ))}
          </div>
          <div>
            <p style={{ fontWeight: 'bold', fontSize: 24, color: '#ffffff' }}>
              M
              <span
                style={{
                  backgroundColor: '#fff',
                  fontSize: 24,
                  padding: '5px 10px',
                  borderRadius: 30,
                  marginLeft: 20,
                  marginRight: 20,
                  color: '#e05c9e',
                }}
              >
                ₪ 15
              </span>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 24, color: '#ffffff' }}>
              L
              <span
                style={{
                  backgroundColor: '#fff',
                  fontSize: 24,
                  padding: '5px 10px',
                  borderRadius: 30,
                  marginLeft: 20,
                  marginRight: 20,
                  color: '#e05c9e',
                }}
              >
                ₪ 20
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthayGroup;
