import React, { useEffect, useState } from 'react';
import useProduct from '../../hooks/useProduct';

function ProductDefiningAttribute({ productId, onChange }) {
  const { attributes } = useProduct(productId);

  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const handleSelectChange = (attributeId, selectedValueId, isSelected) => {
    // Check if the attributeId is already in the state
    const attributeIndex = selectedAttributes.findIndex((attr) => attr.attributeId === attributeId);

    if (attributeIndex !== -1) {
      // If the attribute is already in the state
      const updatedAttributes = [...selectedAttributes];
      if (isSelected) {
        // remove value form selected to handle toggle
        updatedAttributes.splice(attributeIndex, 1);
      } else {
        // update its selectedValueId
        updatedAttributes[attributeIndex] = { attributeId, selectedValueId };
      }
      setSelectedAttributes(updatedAttributes);
    } else {
      // If the attribute is not in the state, add it
      setSelectedAttributes((prevAttributes) => [...prevAttributes, { attributeId, selectedValueId }]);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedAttributes);
    }
  }, [selectedAttributes, onChange]);

  const isAttributeSelected = (attributeId, selectedValueId) => {
    return selectedAttributes.some(
      (attr) => attr.attributeId === attributeId && attr.selectedValueId === selectedValueId,
    );
  };

  return (
    <div className="product-defining-attributes">
      {attributes &&
        attributes
          .filter((a) => parseInt(a.usage) === 1)
          .map((a) => {
            const values = a.values;
            if (!values || values.length === 0) return null;
            return (
              <div className="product-defining-attribute" key={a.id}>
                <p className="product-defining-attribute-title">{a.name}:</p>
                <div className="product-defining-attribute-options">
                  {values.map((v, i) => {
                    const isSelected = isAttributeSelected(a.id, v.attribute_value_id);
                    const className = isSelected
                      ? 'product-defining-attribute-option selected'
                      : 'product-defining-attribute-option ';
                    return (
                      <div
                        key={i}
                        className={className}
                        onClick={() => handleSelectChange(a.id, v.attribute_value_id, isSelected)}
                      >
                        {v.value}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default ProductDefiningAttribute;
