import React, { useEffect } from 'react';
import CustomHelmet from '../components/common/CustomHelmet';
import Loading from '../components/common/Loading';
import useConfig from '../hooks/useConfig';
import useMenu from '../hooks/useMenu';

function CommonLayout(props) {
  const { id, faviconURL, name, description, version } = useMenu();
  const { currentLanguage } = useConfig();

  useEffect(() => {
    if (id) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `/styles/menu_${id}.css?vesion=${version}`; // Adjust path as needed
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link); // Cleanup on unmount
      };
    }
  }, [id]);

  return (
    <section className={`layout common-layout menu-${id}`}>
      <CustomHelmet
        favicon={faviconURL}
        name={name}
        description={description}
        lang={currentLanguage?.code}
        direction={currentLanguage?.dir}
      />

      {/* Render Template only if menu is loaded */}
      {id && props.children}

      {id == null && <Loading />}
    </section>
  );
}

export default CommonLayout;
