import React from 'react';
import useMenu from '../hooks/useMenu';

function MenuSocialLink({ containerStyle }) {
  const { facebook, instagram, phone } = useMenu();

  return (
    <div className="social-links" style={containerStyle}>
      {facebook && (
        <a target="_blank" href={facebook} rel="noopener noreferrer">
          <img src="/assets/icon/facebook.png" alt="Facebook" />
        </a>
      )}
      {instagram && (
        <a target="_blank" href={instagram} rel="noopener noreferrer">
          <img src="/assets/icon/instagram.png" alt="Instagram" />
        </a>
      )}
      {phone && (
        <a target="_blank" href={'tel:' + phone} rel="noopener noreferrer">
          <img src="/assets/icon/phone.png" alt="Phone" />
        </a>
      )}
    </div>
  );
}

export default MenuSocialLink;
