import React from 'react';
import { Product } from './Product';
import useMenu from '../../hooks/useMenu';

const ColdCaffeineGroup = ({ products }) => {
  const { id: menuId } = useMenu();

  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => ['MA1077', 'MA1078', 'MA1079', 'MA1080'].indexOf(p.partnumber) === -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        <p
          style={{
            textAlign: 'center',
            color: '#000000',
            margin: 0,
            fontWeight: 'bold',
            fontSize: 22,
            textDecoration: 'underline',
          }}
        >
          ICE DRINKS
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {products
              .filter((p) => ['MA1077', 'MA1078', 'MA1079', 'MA1080'].indexOf(p.partnumber) !== -1)
              .map((product, index) => (
                <Product key={index} product={product} />
              ))}
          </div>
          <div>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              <span>S</span>
              <span
                style={{
                  backgroundColor: '#fff',
                  fontSize: 16,
                  padding: '5px 10px',
                  borderRadius: 30,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                ₪ {menuId == 28 ? '10' : '15'}
              </span>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              M
              <span
                style={{
                  backgroundColor: '#fff',
                  fontSize: 16,
                  padding: '5px 10px',
                  borderRadius: 30,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                ₪ {menuId == 28 ? '15' : '20'}
              </span>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              L
              <span
                style={{
                  backgroundColor: '#fff',
                  fontSize: 16,
                  padding: '5px 10px',
                  borderRadius: 30,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                ₪ {menuId == 28 ? '20' : '25'}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColdCaffeineGroup;
