import React, { useState } from 'react';
import useMenu from '../../hooks/useMenu';

function ToppingWidget() {
  const { id, selectedCategory, getCategoryById, getProductsInfoByIds } = useMenu();
  const [open, setOpen] = useState(false);

  const toppingCategory = getCategoryById(id == 24 ? 173 : 185);
  const group = toppingCategory?.groups?.[0];
  const products = getProductsInfoByIds(group?.items);

  if ([167, 168, 179, 180].indexOf(parseInt(selectedCategory.id)) != -1) {
    return (
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
          padding: 5,
          borderTop: '1px dashed #0F0F0F',
          borderBottom: '1px dashed #0F0F0F',
        }}
      >
        <h3 onClick={() => setOpen(!open)} style={{ margin: 0 }}>
          תוספות מעל
        </h3>

        <img
          src={`/assets/icon/arrow-${open ? 'down' : 'up'}-white.svg`}
          style={{ position: 'absolute', left: 0, top: 3 }}
          width={30}
          onClick={() => setOpen(!open)}
          alt="expand"
        />
        <div style={{ display: open ? 'block' : 'none', paddingTop: 15 }}>
          {products?.map((p) => {
            return (
              <div key={p.id} product-id={p.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ margin: 0, marginBottom: 5 }}>{p.name}</p>
                <p style={{ color: '#c22014', margin: 0 }}>{p.price} ש“ח</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
}

export default ToppingWidget;
