import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UtilityService from '../services/utility';

function useProduct(productId) {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const { productId: selectedProductId } = useParams();

  const product = menu?.products?.find((p) => parseInt(p.id) === parseInt(productId ? productId : selectedProductId));

  let availableItems = product?.items;
  const dineInOrTakeaway = 'DINE_IN'; //props.config.dineInOrTakeaway;
  const dineInExtraCostEnabled = menu?.settings?.DINE_IN_EXTRA_COST_ENABLED === 'true';

  if (dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
    availableItems = availableItems?.filter((p) => UtilityService.isAllowToDineIn(p));
  }
  const getProductById = (productId) => {
    return menu?.products?.find((p) => parseInt(p.id) === parseInt(productId));
  };

  const getProductByIds = (productIds) => {
    return menu?.products?.filter((p) => productIds.indexOf(p.id) !== -1);
  };

  let thumbnail = product?.media?.[0]?.path;

  if (thumbnail && thumbnail.indexOf('?') === -1) {
    thumbnail = thumbnail + '?version=' + menu.version;
  }

  const resolveSelectedItem = (selectedAttributes) => {
    if (product.type === 'COMPLEX_PRODUCT' && product.items) {
      let filterItems = product.items;

      selectedAttributes.forEach((element) => {
        filterItems = filterItems?.filter((i) => {
          for (let x = 0; x < i.attributes.length; x++) {
            if (i.attributes[x]['attribute_' + element.attributeId] == element.selectedValueId) {
              return true;
            }
          }
          return false;
        });
      });

      const definingAttributes = product.attributes.filter((a) => parseInt(a.usage) === 1);
      if (selectedAttributes.length === definingAttributes.length && filterItems.length > 0) {
        return filterItems[0];
      }
    }
    return null;
  };

  const getMerchandisingAssociations = () => {
    return product?.merchandisingAssociations?.map((ass) => {
      return { ...ass, ...getProductById(ass.productId) };
    });
  };

  return {
    ...product,
    resolveSelectedItem: resolveSelectedItem,
    availableItems,
    thumbnail: thumbnail,
    getProductById: getProductById,
    getProductByIds: getProductByIds,
    merchandisingAssociations: getMerchandisingAssociations,
  };
}

export default useProduct;
