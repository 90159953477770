import React from 'react';

function Text({ group, active, onSelect }) {
  return (
    <div className={'group ' + (active ? 'active' : '')} group-id={group.id} key={group.id} onClick={() => onSelect()}>
      {group.name}
    </div>
  );
}

export default Text;
