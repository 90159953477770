import React from 'react';

function Footer({ name, website, websiteUrl }) {
  return (
    <div className="footer">
      <p>
        Powered by © {new Date().getFullYear()} {name || 'GetMenu'}
      </p>
      <a target="_blank" rel="noopener noreferrer" href={websiteUrl || 'https://www.getmenu.ps'}>
        {website || 'www.getmenu.ps'}
      </a>
    </div>
  );
}

export default Footer;
