import React, { useEffect, useState } from 'react';
import useMenu from '../../hooks/useMenu';
import Text from './Text';
import Icon from './Icon';
import useDesign from '../../hooks/useDesign';

function GroupNavigation({ onChange }) {
  const { selectedCategory } = useMenu();

  const { groupNavigationType } = useDesign();

  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const groupList = selectedCategory?.groups.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));

  useEffect(() => {
    if (onChange) {
      onChange(selectedGroupId);
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      setSelectedGroupId(groupList[0].id);
    }
  }, [selectedCategory]);

  // don't render navigation if only one group
  if (groupList && groupList.length < 2) {
    return null;
  }

  return (
    <div className={'group-navigation group-navigation-' + groupNavigationType}>
      <div className="group-list">
        {selectedCategory &&
          selectedCategory.groups
            ?.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
            .map((group) => {
              const active = selectedGroupId === group.id;
              if (groupNavigationType === 'ICON') {
                return (
                  <Icon key={group.id} group={group} active={active} onSelect={() => setSelectedGroupId(group.id)} />
                );
              } else {
                return (
                  <Text key={group.id} group={group} active={active} onSelect={() => setSelectedGroupId(group.id)} />
                );
              }
            })}
      </div>
    </div>
  );
}

export default GroupNavigation;
