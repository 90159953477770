import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import useConfig from '../hooks/useConfig';
import useMenu from '../hooks/useMenu';
import { SUPPORTED_CURRENCY, SUPPORTED_LANGUAGE } from '../utils/constant';
import { getCurrency, getLanguage } from '../utils/utility';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 10,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function LocalSwitchModal({ open, onClose, children }) {
  const { t } = useTranslation();
  const { id, supported_lang, supported_currency } = useMenu();
  const { currentCurrency, currentLanguage } = useConfig();

  const [currencyId, setCurrnecyId] = useState(currentCurrency?.id);
  const [languageId, setLanguageId] = useState(currentLanguage?.id);

  const handleApply = () => {
    localStorage.setItem('menu_language_' + id, JSON.stringify(getLanguage(languageId)));
    localStorage.setItem('menu_currency_' + id, JSON.stringify(getCurrency(currencyId)));
    localStorage.setItem('menu_i18nextLng', getLanguage(languageId).code);

    onClose();
    window.location.reload();
  };

  const handleLanguageChange = (l) => {
    setLanguageId(l.id);
  };

  const handleCurrencyChange = (c) => {
    setCurrnecyId(c.id);
  };

  const menuSupportedLang = supported_lang?.split(',').map((e) => parseInt(e));
  const menuSupportedCurrency = supported_currency?.split(',').map((e) => parseInt(e));

  return (
    <>
      {children}

      <Modal isOpen={open} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
        <div className="local-switch-modal">
          <img className="close" src="/assets/icon/close.svg" onClick={onClose} alt={t('Close')} />
          <div>
            <p>{t('Language')}:</p>
            <ul>
              {SUPPORTED_LANGUAGE.filter((l) => menuSupportedLang?.indexOf(l.id) !== -1).map((l) => {
                return (
                  <li
                    key={l.id}
                    className={l.id === languageId ? 'active' : ''}
                    onClick={() => handleLanguageChange(l)}
                  >
                    {l.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <p>{t('Currency')}:</p>
            <ul>
              {SUPPORTED_CURRENCY.filter((c) => menuSupportedCurrency?.indexOf(c.id) !== -1).map((c) => {
                return (
                  <li
                    key={c.id}
                    className={c.id === currencyId ? 'active' : ''}
                    onClick={() => handleCurrencyChange(c)}
                  >
                    {c.title} ({c.symbol})
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="actions">
            <div className="button-apply" onClick={handleApply}>
              {t('Apply')}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LocalSwitchModal;
