import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useMenu from '../../hooks/useMenu';
import CategoryNavigation from './../../components/CategoryNavigation';
import Group from './Group';
import GroupNavigation from './../../components/GroupNavigation';

const ProductListPage = () => {
  const { selectedCategory, id: menuId } = useMenu();
  const [selectedGroup, setSelectedGroup] = useState(null);

  const groupList = selectedCategory?.groups.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      setSelectedGroup(groupList[0]);
    }
  }, [selectedCategory]);

  const hanleGroupChanged = (groupId) => {
    const g = groupList.find((group) => groupId === group.id);
    setSelectedGroup(g);
  };

  const displayOffer =
    (parseInt(menuId) === 23 && parseInt(selectedCategory.id) === 164) ||
    (parseInt(menuId) === 28 && parseInt(selectedCategory.id) === 192);

  return (
    <div className="two-page-layout product-list-page main-card masteck-ananas-template">
      <Header showBackButton={false} showShareButton={true} showBusinessDetails={true} />

      <CategoryNavigation />

      {displayOffer ? (
        <div style={{ position: 'relative', padding: '0 30px' }}>
          <span
            style={{
              position: 'absolute',
              right: 45,
              bottom: 20,
              backgroundColor: '#5cb7b1',
              padding: 8,
              borderRadius: 10,
              fontWeight: 'bold',
            }}
          >
            بوكس السنافر <span>₪ 95.0</span>
          </span>
          <img
            style={{ width: '100%', borderRadius: 30, border: '2px solid #5cb7b1' }}
            src="https://getmenu.b-cdn.net/accounts/3/category/special_offer.jpg"
            alt="sweets"
          />
        </div>
      ) : (
        <div style={{ width: 220, border: '1px solid #FFFFFF', margin: '10px auto' }}></div>
      )}

      <GroupNavigation onChange={hanleGroupChanged} selectedGroup={selectedGroup} />

      <div style={{ padding: 30 }}>{selectedGroup && <Group key={selectedGroup.id} group={selectedGroup} />}</div>

      <Footer />
    </div>
  );
};

export default ProductListPage;
