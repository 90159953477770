import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDesign from '../../hooks/useDesign';
import useQRCode from '../../hooks/useQRCode';

function ProductName({ name, productId }) {
  const { pdpEnabled } = useDesign();
  const navigate = useNavigate();
  const { uuid } = useQRCode();

  const navigateToProduct = () => {
    navigate(`/m/${uuid}/product/${productId}`);
  };

  return (
    <div className="product-name">
      {pdpEnabled && <p onClick={navigateToProduct}>{name}</p>}
      {!pdpEnabled && <p>{name}</p>}
    </div>
  );
}

export default ProductName;
